$(function(){
  $('.switch-lang-link-hk, .switch-lang-link').on('click', function(event) {
      event.preventDefault();
      return switchLanguageHK( $(this).attr('data-mp-lang') );
  });

  function switchLanguageHK(lang) {
      var domains = { 'zh_HK' : '/', 'en_HK' : '/en-hk/' };
      if (lang != generic.cookie('LOCALE')){
          var path = window.location.pathname.replace(/\/en\-hk/, "");
          document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          generic.cookie('LOCALE', lang, {path : '/'});

          if (path.indexOf(".tmpl") > 0 || path.indexOf(".tmpl") > 0) {
            domains[lang] = '';
          }
          try {
            if (eval("Drupal.settings.elc_common." + lang + "_url") != undefined && path != "/") {
              path = eval("Drupal.settings.elc_common." + lang + "_url");
            }
          }
          catch (error) {}

          if (path == "/") {
            path = "";
          }
          var site_path = window.location.host + domains[lang] + path;
          site_path = site_path.replace(/\/+/g, '/');

          var url = window.location.protocol + '//' + site_path;
          var full_url = window.location.href;
          var appends = ""
          if (full_url.indexOf("#") > 0) {
            var full_url_parts = full_url.split("#");
            appends = "#" + full_url_parts[1];
          } else if (full_url.indexOf("?") > 0) {
            var full_url_parts = full_url.split("?");
            appends = "?" + full_url_parts[1];
          }
          if(window.location.href == url + appends){
            window.location.reload();
          }
          window.location.href = url + appends;
      }

  }

  $(document).ready(function () {
    var path = window.location.pathname;
    var pathArray = path.split('/');
    if (generic.cookie('LOCALE') == 'en_HK' && pathArray[1] != 'en-hk' && path.indexOf(".tmpl") == -1 && path.indexOf(".tmpl") == -1) {
        var full_url = window.location.href;
        var appends = '', full_url_parts; 
        if (full_url.indexOf("#") > 0) {
          full_url_parts = full_url.split("#");
          appends = "#" + full_url_parts[1];
        } else if (full_url.indexOf("?") > 0) {
          full_url_parts = full_url.split("?");
          appends = "?" + full_url_parts[1];
        }
      window.location.href = window.location.protocol + '//' + window.location.host + '/en-hk' + window.location.pathname + appends;
    }
  });
});
